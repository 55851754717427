.loader-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* 60% transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's on top of other elements */

  &.loader-block {
    position: relative;
    background-color: transparent; /* No background for block mode */
    z-index: auto; /* Reset z-index */
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader-text {
  margin-top: 20px;
  color: white;
  font-size: 1.2em;
  text-align: center;

  .loader-text-item {
    animation: fadeInOut 15s linear infinite;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
