.App {
  margin: 0 !important;
  text-align: left;
  font-family: Helvetica, Arial, sans-serif;
  color: white;
  width: 100%;
  height: 100%;
  background-color: #282c34;
  overflow-y: hidden;
}


body,html {
  margin: 0;
  text-align: center;
  background-color: #282c34;
  overflow: hidden; /* Prevents overflow cut off */
  } 