.author-caption-big {
  position: relative;
  z-index: 100;
  padding: 3px;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Gill Sans", sans-serif;
  font-weight: 100;
  cursor: pointer;
  text-align: left; /* Align text to the left */
}

.author-caption-big:hover {
  color: #fff;
  font-weight: 300;
}

.author-caption-big .created-by {
  display: block;
}

.author-caption-big .author-name {
  font-size: 2em;
  text-align: left;
}

@media (max-width: 600px) {
  .author-caption-big {
    font-size: 1rem;
  }
  .author-caption-big .author-name {
    font-size: 1.5em;
  }
}

.signature-link {
  text-decoration: none;
  color: inherit;
  position: relative;
  display: inline-block;
  transition:
    color 0.3s ease,
    opacity 0.3s ease;
}

.signature-link .hover-text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: calc(100% + 5px); /* Position below the element with margin */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 0.75em; /* Smaller font size */
  transition:
    visibility 0s,
    opacity 0.3s linear;
  white-space: nowrap;
  z-index: 1001; /* Ensure it appears above other elements */
}

.signature-link:hover .hover-text {
  visibility: visible;
  opacity: 1;
}

.signature-link:hover {
  color: #ffffff; /* Change the color on hover */
  opacity: 0.8; /* Make the text less transparent */
}

.author-caption-small {
  position: fixed;
  margin-bottom: 15px;
  bottom: 10px;
  right: 10px;
  z-index: 1000; /* Higher z-index to hover over other components */
  padding: 3px;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Gill Sans", sans-serif;
  font-weight: 100;
  cursor: pointer;
  text-align: right; /* Align text to the right */
}

.author-caption-small:hover {
  // color: #fffcfc4d;
  font-weight: 300;
}

.author-caption-small .created-by {
  display: block;
}

.author-caption-small .author-name {
  font-size: 1em;
}

.signature-links {
  display: flex;
  flex-direction: row;
}

.signature-link.michal .hover-text,
.signature-link.yx .hover-text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: calc(100% + 1px); /* Position below the element with margin */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 2px 2px;
  border-radius: 3px;
  font-size: 0.75em; /* Smaller font size */
  transition:
    visibility 0s,
    opacity 0.3s linear;
  white-space: nowrap;
  z-index: 1001; /* Ensure it appears above other elements */
}

.signature-link.michal:hover .hover-text,
.signature-link.yx:hover .hover-text {
  visibility: visible;
  padding: 6px;
  opacity: 1;
}

.signature-link.michal:hover,
.signature-link.yx:hover {
  color: #ffffff; /* Change the text color to black */
  opacity: 1; /* Make the text fully opaque */
  font-weight: bolder;
}
