.shadow {
  position: fixed; /* Change to fixed to cover the entire screen */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(7, 7, 8, 0.96);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  z-index: 1; /* Ensure the z-index is below other content */
}

.show-over-shadow {
  z-index: 2; /* Ensure the content is above the shadow */
  position: relative;
}
