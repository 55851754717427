.spinning-graph-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.spinning-graph {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; // Ensure the spinning graph is on top
}

.node-text-foreign {
  pointer-events: none;
}

.node-text {
  color: white;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
