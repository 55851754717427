.graph {
  width: 100%;
  height: 100%;
}

.node {
  stroke: #85affb;
  fill: #85affb;
  stroke-width: 1px;
  transition:
    fill 0.2s,
    filter 0.2s;
}

.edge {
  stroke-width: 3px;
  transition:
    stroke 0.2s,
    filter 0.2s;
}

.edge.hovered-edge-1 {
  stroke-width: 3px;
  stroke: rgba(251, 97, 97, 0.8);
  filter: drop-shadow(0 0 5px rgba(251, 97, 97, 0.8));
}

.edge.hovered-edge-2 {
  stroke-width: 3.5px;
  filter: drop-shadow(0 0 10px rgba(247, 144, 144, 0.5));
}

.node.hovered-node-1 {
  fill: #fb6161;
  stroke: rgba(251, 97, 97, 0.8);
  stroke-width: 4px;
  filter: drop-shadow(0 0 5px rgba(251, 97, 97, 0.8));
}

.node.hovered-node-2 {
  fill: #fcb8b8;
  stroke: #ffc1c1;
  stroke-width: 3.5px;
  filter: drop-shadow(0 0 10px rgba(247, 144, 144, 0.5));
}

.node.hovered-node-3 {
  fill: #85affb;
  stroke: #85affb;
  .graph-formats {
    position: absolute;
    top: 0;
    right: 0;
    width: 25vw;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;

    h3 {
      margin-top: 0;
      text-align: center;
      color: white;
      border-bottom: 1px solid white;
      padding-bottom: 10px;
    }

    .format-section {
      margin-bottom: 20px;

      h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        margin: 20px 0 10px;
        color: white;
        border-bottom: 1px solid white;
        padding-bottom: 5px;
      }

      pre {
        background-color: #333;
        padding: 10px;
        border-radius: 5px;
        overflow-x: auto;
      }

      .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 6px;
      }

      .grid-item {
        min-width: 16px;
        background-color: #444;
        padding: 6px;
        border-radius: 3px;
        word-break: break-word;

        &.hovered {
          background-color: #fb6161 !important;
        }

        &.traversed {
          background-color: #b49bf5;
        }
      }

      .matrix-container {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        overflow-x: scroll;
        max-width: 100%;
        white-space: nowrap;
      }

      .matrix-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #555;

        &:first-child {
          border-bottom: none;
          background-color: #333;
          border-bottom: 1px solid #555;
        }
        &:last-child {
          border-bottom: none;
        }
      }

      .matrix-header-cell {
        background-color: #333;
        padding: 0px;
        min-width: 12px;
        max-width: 12px;

        .matrix-head-cell-container {
          transform: scale(0.7) translateX(-3px);
          text-align: center;
        }
      }

      .matrix-header-cell,
      .matrix-cell {
        min-width: 12px;
        max-width: 12px;
        padding: 3px;
        text-align: center;
        border-right: 1px solid #555;

        &:first-child {
          min-width: 22px;
          max-width: 22px;
          border-right: 1px solid #555;
        }
        &:last-child {
          border-right: none;
          max-width: 12px;
        }

        // hover
        &:hover {
          background-color: #fb6161 !important;
        }

        &.hovered {
          background-color: #fb6161 !important;
        }

        &.traversed {
          background-color: #b49bf5;
        }
      }

      .matrix-row-id {
        min-width: 18px;
        padding: 5px;
        text-align: center;
        background-color: #333;
        color: white;
        border-right: 1px solid #555;
        &:hover {
          background-color: #fb6161 !important;
        }

        &.hovered {
          background-color: #fb6161 !important;
        }

        &.traversed {
          background-color: #b49bf5;
        }
      }
    }

    .highlight {
      background-color: #fb6161;
    }

    .hovered {
      background-color: #fb6161 !important;
    }

    .traversed {
      background-color: #b49bf5;
    }

    .slide-enter {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
    }

    .slide-enter-active {
      max-height: 500px; // Adjust as necessary
      opacity: 1;
      transition:
        max-height 300ms ease-in-out,
        opacity 300ms ease-in-out;
    }

    .slide-exit {
      max-height: 500px; // Adjust as necessary
      opacity: 1;
    }

    .slide-exit-active {
      max-height: 0;
      opacity: 0;
      transition:
        max-height 300ms ease-in-out,
        opacity 300ms ease-in-out;
    }
  }

  stroke-width: 2px;
  filter: drop-shadow(0 0 5px rgba(133, 175, 251, 0.8));
}

.current-node {
  fill: #e34bf1;
  stroke: #ea67f5;
  stroke-width: 2px;
  filter: drop-shadow(0 0 5px rgba(234, 103, 245, 0.8));
}

.traversed-node {
  fill: #934bf2;
  stroke: #934bf2;
  stroke-width: 2px;
  filter: drop-shadow(0 0 5px rgba(180, 155, 245, 0.8));
}

.traversed-edge {
  stroke: #934bf2;
  filter: drop-shadow(0 0 5px rgba(180, 155, 245, 0.8));
}
