#particles-js {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0; // Ensure particles are behind other elements
  }
  
  .count-particles {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #ffffff;
    font-size: 12px;
  }
  